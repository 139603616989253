.Experience {
    margin-bottom: 5rem;
}

.Experience__dates {
    margin: 0.25rem 0 1rem;
    font-weight: 400;
}

.Experience__context {
    font-size: 0.95rem;
}

.Experience__company {
    margin: 0;
    font-size: 1.5rem;
}

.Experience__description {
    line-height: 1.5;
}

.Experience__extras {
    padding-left: 1rem;
}

@media screen and (min-width: 800px), print {
    .Experience {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 2rem;
    }

    .Experience__intro {
        width: 30%;
        flex-shrink: 0;
    }

    .Experience__content {
        padding-left: 2rem;
    }
}
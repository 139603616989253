.Home__section {
    margin-left: auto;
    margin-right: auto;
    max-width: 777px;
    text-align: center;
    font-size: 1.5rem;
    line-height: 1.5;
    font-family: 'National Park', sans-serif;
}

.Home__name {
    display: inline-block;
    font-family: 'Blackout', sans-serif;
    font-size: 4rem;
    line-height: 1.2;
    text-transform: uppercase;
    transform: skewY(2deg) skewX(-4deg);
    /* transform-origin: 0 0; */
}

.Home__intro1 {
    padding: 0.5rem 1.5rem;
    /* box-shadow: -0.5rem 0.5rem 0 0 red; */
}

.Home__intro2 {
    padding: 0.5rem 1.5rem;
    /* box-shadow: -0.5rem 0.5rem 0 0 cyan; */
}

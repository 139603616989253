.Resume__main {
    margin: 2rem auto 10rem;
    max-width: 800px;
    font-size: 1.25rem;
    padding: 0 1rem;
}

.Resume__heading {
    font-family: 'Blackout', sans-serif;
    font-size: 2.5rem;
}

.Resume__section {

}

.Resume__separator {
    margin: 1rem 0 4rem;
}

@media screen and (min-width: 800px), print {
    .Resume__intro {
        display: flex;
        flex-wrap: nowrap;
    }

    .Resume__heading {
        width: 30%;
        flex-shrink: 0;
    }

    .Resume__bio {
        margin-top: 0.5rem;
        padding-left: 2rem;
    }
}

@media print {
    .Resume__separator {
        display: none !important;
    }
}
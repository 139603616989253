.Art__image-grid {
    display: grid;
    justify-items: center;
    grid-template-columns: 1fr;
}

/* For desktop resolutions */
@media (min-width: 768px) {
    .Art__image-grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

/* For mobile viewports */
@media (max-width: 1200px) {
    .Art__image-grid {
        grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    }
}


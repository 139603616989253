.Skill {
    margin-bottom: 2rem;
}

.Skill__heading {
    font-size: 1.25rem;
    font-weight: 400;
}

.Skill__list {
    list-style: none;
}

.Skill__item {
    line-height: 1.5;
    margin: 0 0 1rem;
}

@media screen and (min-width: 800px), print {
    .Skill {
        display: flex;
        flex-wrap: nowrap;
    }

    .Skill__heading {
        width: 30%;
        flex-shrink: 0;
        margin: 0;
    }

    .Skill__list {
        padding-left: 2rem;
        margin: 0;
    }
}
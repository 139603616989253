@font-face {
  font-family: 'National Park';
  src: url(./assets/fonts/NationalPark-Regular.woff2) format('woff2');
  font-weight: 400;
}

@font-face {
  font-family: 'National Park';
  src: url(./assets/fonts/NationalPark-SemiBold.woff2) format('woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'Blackout';
  src: url(./assets/fonts/Blackout.ttf) format('truetype');
}



html {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-smooth: always;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(0,0,0,0);

  background-color: rgb(210,210,210);
  color: black;
  font-family: "National Park", sans-serif;
}

* {
  box-sizing: border-box;
}

/* #root { */
.full {
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  perspective: 1000px;
  user-select: none;
}

::-moz-selection {
  color: lightgrey;
  background: black;
}
::selection {
  color: lightgrey;
  background: black;
}

*, *:before, *:after {
  box-sizing: inherit;
}

img {
  max-width: 100%;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
}

/*
a[href^="http"]::after {
  content: ' ✧';
  font-style: normal;
  font-weight: normal;
}
*/

